@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html {
  background-color: #f5f7fa;
}

.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f7fa;
}

.matter-form {
  width: 30%;
  padding: 30px;
  background-color: #3b525c;
  color: #fefefe;
  position: relative;
  z-index: 1;
}

@media (max-width: 850px) {
  .matter-form {
    width: 90%;
  }
}

.matter-form input,
textarea,
.matter-form select {
  background-color: #4d616c;
  border: none;
  color: #fff;
  -webkit-text-fill-color: #fff; /* Override default autofill text color */
  transition: background-color 5000s ease-in-out 0s; /* Override autofill background color */
}

input::placeholder,
textarea::placeholder {
  color: #fff;
}

textarea {
  resize: none;
}

form {
  border: 1px solid #949494;
}

form button {
  background-color: #4a82b0;
}

form button:hover {
  opacity: 0.7;
}

.main-heading {
  color: #da1884;
}

.header-image {
  margin-left: auto;
  margin-right: auto;
  height: 100px;
}

select {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 100% 50%;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline-width: 0;

  display: block;
  width: 10em;
  border: none;
  font-size: 1rem;
  color: #fff;
}

.required {
  color: rgb(204, 68, 68);
  font-weight: 100;
}

label {
  opacity: 0.7;
}

.form-container {
  position: relative;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.background-image-1 {
  position: fixed;
  right: -10px;
  transform: rotate(160deg);
  translate: 300px 300px;
  height: 900px;
}

.background-image-2 {
  position: fixed;
  right: -10px;
  height: 800px;
  transform: rotate(280deg);
  translate: -300px 650px;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-text-fill-color: #fff; /* Autofill text color */
  -webkit-box-shadow: 0 0 0px 1000px #4d616c inset; /* Autofill background color */
  box-shadow: 0 0 0px 1000px #4d616c inset; /* Autofill background color */
}

input:-webkit-autofill:hover,
textarea:-webkit-autofill:hover,
select:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill:active,
select:-webkit-autofill:active {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #4d616c inset;
  box-shadow: 0 0 0px 1000px #4d616c inset;
}

input:-webkit-autofill {
  background-clip: text;
}

.cloud-law-heading {
  font-size: 3rem;
}
